.select-container
    display: flex
    flex-direction: column
    margin-top: 30px
    border: 1px solid #1E1E70
    padding: 12px 16px
    border-radius: 10px
    opacity: 0.5
    &.focused
        opacity: 1
    &.error
        border-color: #B3040F

.select-container:focus-within
    opacity: 1

.select-values-container
    display: flex
    flex-direction: row
    flex-wrap: wrap

.selected-value
    padding: 10px
    margin-right: 10px
    margin-bottom: 10px
    border: solid
    display: flex
    flex-direction: row
    border: 0.25px solid #1E1E70
    border-radius: 10px

.selected-value-text
    @extend .beaufortPro-normal
    margin-right: 10px
    font-size: 18px !important

.selected-value-icon
    margin-top: 5px
    cursor: pointer

.select-options-container
    display: flex
    flex-direction: row
    justify-content: space-between
    margin-top: 10px

.select-options-text
    @extend .beaufortPro-normal
    font-size: 18px !important

.select-options-item-container
    margin-top: 20px
    max-height: 410px
    overflow: scroll

.select-options-item
    @extend .beaufortPro-normal
    font-size: 18px !important
    margin-top: 15px
    cursor: pointer
    &.disabled
        opacity: 0.5
        cursor: auto
        .select-item-disabled-text
            font-family: 'Dia Normal'

.select-icon
    transform: rotate(0)
    transition: trasform 0.3s ease
    &.open
        transform: rotate(180deg)