.input-container
    display: flex
    flex-direction: column
    margin-top: 30px
    border: 1px solid #1E1E70
    padding: 12px 16px
    border-radius: 10px
    opacity: 0.5
    &.error
        border-color: #B3040F
    .prefix
        display: flex
        align-items: center
        margin-right: 1px
        font-size: 18px
    &.focused
        opacity: 1
        .date-picker
            display: block
            .react-calendar__tile--now
                background: transparent
                border: 1px solid #1E1E70
            .react-calendar__navigation__label__labelText
                font-family: "Dia Normal"
                font-style: normal
                font-size: 14px
                line-height: 24px
                color: #1E1E70
            abbr[title]
                text-decoration: none
            .react-calendar__tile
                font-family: "Dia Normal"
                font-style: normal
                font-size: 14px
                line-height: 24px
                color: #1E1E70
            .react-calendar__tile--hasActive,
            .react-calendar__tile--active
                background: #1E1E70
                color: #fff
        .date-picker-view
            display: none
.input-container:focus-within
    opacity: 1

.input, .input:focus
    @extend .beaufortPro-normal
    border: none
    outline: none
    color: none
    background: none
    font-size: 18px !important
    width: 95%

.input-label
    @extend .dia-normal
    margin-bottom: 16px
    &.error
        color: #B3040F !important

.input-label-error
    @extend .dia-normal
    color: #B3040F !important
    margin-left: 20px
    margin-top: 8px
    display: block
    text-transform: lowercase
    &:first-letter
        text-transform: uppercase

.button-container
    margin: 30px 0

.date-picker
    display: none
    z-index: 10
    &.input
        padding-left: 0
        &::-webkit-date-and-time-value
            text-align: left

.date-picker-view
    position: relative

input[type="date"]::-webkit-calendar-picker-indicator
    background: transparent
    bottom: 0
    color: transparent
    cursor: pointer
    height: auto
    left: 0
    position: absolute
    right: 0
    top: 0
    width: auto

.input:-internal-autofill-selected
    background-color: transparent

input::placeholder
  color: #1E1E70

input::-ms-input-placeholder
  color: #1E1E70

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active
    -webkit-box-shadow: 0 0 0 40px #fbf6ef inset !important
    color: #1E1E70 !important