.multi-select
    .multi-select-container
        display: flex
        flex-wrap: wrap
    .multi-select-option
        border: 1px solid #E6DFD5
        border-radius: 4px
        padding: 10px 24px
        margin-right: 3px
        margin-bottom: 14px
        text-transform: lowercase
        cursor: pointer
        &.selected
            border: 1px solid #1E1E70
        &:first-letter
            text-transform: uppercase
        &:not(:first-child)
            margin-left: 4px
    .multi-select-label-error
        color: #B3040F
        margin-left: 20px
        margin-top: 8px
        display: block
        text-transform: lowercase
        &:first-letter
            text-transform: uppercase