.checkbox-wrapper
    label
        display: flex
        align-items: center
        cursor: pointer
    input
        display: none
    .indicator-wrap
        display: flex
        align-items: center
        justify-content: center
        position: relative
        width: 22px
        height: 22px
        border-radius: 3px
        border: 1px solid #E6DFD5
        margin-right: 24px
        cursor: pointer
        &.checked
            border: 1px solid transparent
    .indicator
        position: absolute
        width: 100%
        height: 100%
        z-index: 1
    .label-wrap
        display: flex
        flex-direction: column
        max-width: 294px

