.check-out-page
    position: relative
    .event-container
        width: 100%
        display: flex
        flex-direction: column
    .event-image
        width: 100%
        border-top-right-radius: 10px
        border-top-left-radius: 10px
    .details-wrap
        display: flex
        flex-direction: column
        padding: 16px 16px 24px
        max-height: 200px
        border-left: 1px solid rgb(30 30 112 / 50%)
        border-right: 1px solid rgb(30 30 112 / 50%)
        border-bottom: 1px solid rgb(30 30 112 / 50%)
        border-bottom-right-radius: 10px
        border-bottom-left-radius: 10px
        overflow-y: auto
        .title
            margin-bottom: 14px
            color: #1E1E70
    .submit-btn
        margin-top: 24px
        margin-bottom: 32px
    .location-wrap
        display: flex
        margin-bottom: 14px
        .location
            margin-left: 8px
    .pricing-wrap
        display: flex
        margin-bottom: 14px
        img
            margin-right: 8px
    .address-details
        margin-bottom: 14px