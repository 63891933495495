.radio-wrap
    .radio-group
        border: 1px solid #E6DFD5
        border-radius: 4px
        display: flex
        flex-direction: column
        align-items: center
        padding: 24px 0
        .radio-group-options
            display: flex
        .radio-group-title
            margin-bottom: 29px
            line-height: 24px
        .radio-option
            border: 1px solid #E6DFD5
            border-radius: 4px
            padding: 10px 24px
            text-transform: lowercase
            cursor: pointer
            &.selected
                border: 1px solid #1E1E70
            &:first-letter
                text-transform: uppercase
            &:not(:last-child)
                margin-right: 8px
    .radio-group-label-error
        color: #B3040F
        margin-left: 20px
        margin-top: 8px
        display: block