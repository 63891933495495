.button
    width: 100%
    background-color: #1E1E70
    border-radius: 100px
    border: none
    padding: 21px
    cursor: pointer
    &:hover
        opacity: 0.85
    &:active
        background: #1E1E70
        opacity: 0.85
    &:active
        background: #1E1E70
        opacity: 0.85
    &:disabled
        opacity: 0.4
        pointer-events: none
        cursor: default
    .button-text
        color: #FFFFFF
        text-transform: uppercase
        padding-top: 22px
        padding-bottom: 22px
        font-size: 14px   
        line-height: 24px
    .disabled
        opacity: 0.4
        pointer-events: none
        cursor: default