.banner-container
    display: flex
    flex-direction: column
    width: 100%
    background-color: #FBF6EF
    border: 0.5px solid #E6DFD5
    border-radius: 4px

.banner-text-container
    display: flex
    flex-direction: column
    text-align: center
    padding: 24px 12px

.banner-main-text
    margin-bottom: 4px

.banner-subtitle
    text-transform: uppercase
    &:not(:last-child)
        margin-bottom: 4px

.banner-description
    @extend .dia-normal
    margin-top: 4px
    margin-bottom: 8px
