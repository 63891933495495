.logout-wrap
    width: 100%
    display: flex
    justify-content: center
    margin: 62px 0
    .logout
        text-transform: uppercase
        cursor: pointer
        opacity: 1
        &:hover
            opacity: 0.8