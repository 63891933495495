@import './pages/index.sass'
@import './components/index.sass'
@import './components/reset.css'

@font-face
    font-family: 'Dia Normal'
    src: url("/assets/fonts/DiaRegular.otf") format("opentype")

@font-face
    font-family: 'Dia Bold'
    src: url("/assets/fonts/DiaBold.otf") format("opentype")

@font-face
    font-family: 'BeaufortPro Normal'
    src: url("/assets/fonts/BeaufortPro.otf") format("opentype")

@font-face
    font-family: 'Dia Black'
    src: url("/assets/fonts/DiaBlack.otf") format("opentype")

html
    height: 100%
    width: 100%
    background-color: #fbf6ef

body
    height: 100%

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button
    /* display: none <- Crashes Chrome on hover */
    -webkit-appearance: none
    margin: 0

input[type=number]
    -moz-appearance: textfield

.dia-normal
    font-family: 'Dia Normal'
    font-style: normal
    font-size: 14px
    line-height: 20px
    color: #1E1E70

.dia-bold
    font-family: 'Dia Bold'
    font-style: normal
    font-weight: 900
    font-size: 14px
    line-height: 24px
    color: #1E1E70

.dia-md
    font-family: 'Dia Normal'
    font-weight: 400
    font-size: 16px
    line-height: 24px
    color: #1E1E70

.dia-thin
    font-family: 'Dia Bold'
    font-size: 12px
    line-height: 24px
    color: #1E1E70

.beaufortPro-normal
    font-family: 'BeaufortPro Normal'
    font-style: normal
    font-size: 14px
    line-height: 24px
    color: #1E1E70

.beaufortPro-md
    font-family: 'BeaufortPro Normal'
    font-weight: 400
    font-size: 20px
    line-height: 33px
    color: #FBF6EF
    letter-spacing: 2px

.beaufortPro-thin
    font-family: 'BeaufortPro Normal'
    font-weight: 400
    font-size: 12px
    line-height: 24px
    color: #1E1E70

.beaufortPro-lg
    font-family: 'BeaufortPro Normal'
    font-weight: 400
    font-size: 42px
    line-height: 44px
    letter-spacing: -1px
    color: #FBF6EF

.dia-title
    font-family: 'Dia Normal'
    font-weight: 400
    font-size: 20px
    line-height: 33px
    letter-spacing: 1px
    color: #FBF6EF

.title-bold
    font-family: 'Dia Bold'
    font-size: 16px
    line-height: 24px
    color: #1E1E70

.container
    width: 100%
    max-width: 360px

.page
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column
