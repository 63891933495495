.header
    display: flex
    justify-content: center
    align-items: center
    margin-top: 56px
    margin-bottom: 48px
    &.header-back-btn
        .step-arrow-back
            display: flex
            align-items: center
            justify-content: center
            position: absolute
            left: 32px
            width: 24px
            height: 24px
            cursor: pointer
    .header-title
        font-size: 18px
        text-transform: capitalize